// React
import { useState, createContext, useMemo } from 'react'

// Custom
import ScreenDimFunction from 'assets/functions/ScreenDimFunction'

// Types
import { ScreenDim } from 'types/global_types'

export type GlobalContextType = {
  links: {
    linkedin: string
  }
  screenDimensions: ScreenDim

  // changeLanguage: (lang:Languages) => void,
}

const GlobalContext = createContext<GlobalContextType>({
  links: {
    linkedin: 'http://www.linkedin.com'
  },
  screenDimensions: {
    width: 1500,
    height: 1000
  }

  // changeLanguage:   (lang: Languages) => null,
})

// This function manages global data, so that if the user leaves the data analyzer component, e.g., to login his account, he doesn't lose all the progress
function GlobalContextProvider(props: any) {
  const links = {
    linkedin: 'http://www.linkedin.com'
  }

  // const [language, setLanguage] = useState<Languages>('en')
  const [screenDimensions, setScreenDimensions] = useState<ScreenDim>({
    width: 0,
    height: 0
  })

  // const changeLanguage = (lang:Languages) => { setLanguage(lang) }

  const contextVars = useMemo(
    () => ({
      links,
      screenDimensions

      // changeLanguage,
    }),
    [screenDimensions /* , language */]
  )

  return (
    <>
      <ScreenDimFunction
        getScreenDimensions={dim =>
          setScreenDimensions({ width: dim.width, height: dim.height })
        }
      />
      <GlobalContext.Provider value={contextVars}>
        {props.children}
      </GlobalContext.Provider>
    </>
  )
}

export { GlobalContextProvider, GlobalContext }
